<template>
  <div id="app" class="grid grid-cols-1 lg:grid-cols-[250px_1fr] min-h-screen">
    <ToggleNavbar></ToggleNavbar>
      <div class="resto">
      <div class="card" v-for="item in Restaurants" :key="item.name">
        <div class="content">
          <div class="imgBx">
            <img :src="item.image" />
          </div>
          <div class="contentBx">
            <h3>
              {{ item.name }}
              <br />
              <span>{{ item.text }}</span>
            </h3>
          </div>
        </div>
        <ul class="sci">
          <li v-if="item.menu">
            <a :href="item.menu" target="_blank">Menu</a>
          </li>
          <li v-if="item.maps">
            <a :href="item.maps" target="_blank">Location</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style>
.resto {
margin-bottom:10px;
padding:15px;
border:solid 1px;
text-align: left;
justify-content: center;
align-items: center;
flex-wrap: wrap;
position: relative;
z-index: 1;
display: flex;
margin: 5px 0;
}

#layout {
display: grid;
grid-template-areas:
"nav header header"
"nav content content"
"nav content content";
height: 97.9vh;
width: 98vw;
padding : 10px;
grid-template-rows: 80px 1fr;
grid-template-columns: 200px 1fr;
min-height: 100%;
row-gap: 1ch;
column-gap: 1ch;
}

header {
padding: 10px;
border: solid 1px;
grid-area: header;
}

main {
grid-area: content;
padding-bottom: 10px;
}

* {
margin: 0;
padding: 0;
}

body {
background-color: #1e293b;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
min-height: 100vh;
}

.resto .card {
position: relative;
width: 300px;
height: 400px;
background: rgba(185, 203, 236, 0.479);
margin: 20px;
box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
border-radius: 15px;
display: flex;
justify-content: center;
align-items: center;
backdrop-filter: blur(10px);
}

.resto .card .content {
position: relative;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
transition: 0.5s;
}

.resto .card:hover .content {
transform: translateY(-20px);
}

.resto .card .content .imgBx {
position: relative;
width: 150px;
height: 150px;
overflow: hidden;
}

.resto .card .content .imgBx img {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
}

.resto .card .content .contentBx h3 {
color: #fff;
text-transform: uppercase;
letter-spacing: 2px;
font-weight: 500;
font-size: 18px;
text-align: center;
margin: 20px 0 10px;
line-height: 1.1em;
}

.resto .card .content .contentBx h3 span {
font-size: 12px;
font-weight: 300;
text-transform: initial;
}

.resto .card .sci {
position: absolute;
bottom: 50px;
display: flex;
}

.resto .card .sci li {
list-style: none;
margin: 0 10px;
transform: translateY(40px);
transition: 0.5s;
opacity: 0;
}

.resto .card:hover .sci li {
transform: translateY(0px);
opacity: 1;
}

.resto .card .sci li a {
color: #fff;
font-size: 20px;
}
</style>
<script>
import ToggleNavbar from '@/components/ToggleNavbar.vue';

export default {
components: {
  ToggleNavbar
},
methods:  {
toggleMenu() {
    this.menuOpen = !this.menuOpen;
},
openNav() {
  document.getElementById("sidebar").style.width = "250px";
  document.getElementById("main").style.marginLeft = "250px";
},
closeNav() {
  document.getElementById("sidebar").style.width = "0";
  document.getElementById("main").style.marginLeft= "0";
}
},
data: () => {
  return {
    menuOpen: false,
    Restaurants: [
      {
        name: "Menkicchi",
        type: "Ramen",
        image: "menkicchi.jpg",
        address: "41 Rue Sainte-Anne, 75001 Paris",
        text: "One of the best ramen in Paris",
        pref_food: "Miso Tonkotsu",
        menu:
          "https://menkicchi.kintarogroup.com/wp-content/uploads/2022/12/2022-06-08-Menkichi-menu_A5.pdf",
        maps: "https://goo.gl/maps/f9hvRBojBn87mYTL8",
      },
      {
        name: "Jimmy 2 fois",
        image: "jimmy2fois.jpg",
        type: "Pizza",
        address: "15 Rue Letort, 75018 Paris",
        text: "Super tasty pizza",
        pref_food: "Machefaccio",
        menu: "http://jimmy2fois.fr/la-carte",
        maps: "https://goo.gl/maps/erDJLLvBuhj8SADSA",
      },
      {
        name: "Muto Donburi",
        image: "mutodonburi.jpeg",
        type: "Japanese",
        address: "70 Rue Blanche, 75009 Paris",
        text: "Authentic japanese food",
        pref_food: "Tori katsu karé",
        maps: "https://goo.gl/maps/GuWjLbHstvbHp8eY9",
      },
      {
        name: "Johana's Fish & Chips",
        image: "johanas.jpg",
        type: "Fried",
        address: "30 Rue Saint-Sauveur, 75002 Paris",
        text: "Fresh and homemade fried food",
        pref_food: "Chicken burger",
        menu: "https://www.johanasfishandchips.com/commander-1",
        maps: "https://goo.gl/maps/xP6fgcZUbHwUhsuu9",
      },
      {
        name: "Brasserie Dubillot",
        image: "dubillot.png",
        type: "French",
        address: "222 Rue Saint-Denis, 75002 Paris",
        text: "Typical and beautiful french brasserie",
        pref_food: "Hampe de boeuf grillé",
        menu: "https://nouvellegardegroupe.com/brasserie-dubillot/menus/",
        maps: "https://goo.gl/maps/q7s3Ld3S3QxZRM68A",
      },
      {
        name: "Neko Ramen",
        image: "nekoramen.png",
        type: "Japanese",
        address: "6 Rue de la Grange Batelière, 75009 Paris",
        text: "One of the best japanese curry in Paris",
        pref_food: "Karaage curry",
        menu: "https://www.nekoramen.fr/",
        maps: "https://goo.gl/maps/AXLb9CuiAY1E6RUb6",
      },
      {
        name: "Pizza Wawa",
        image: "pizzawawa.jpeg",
        type: "Pizza",
        address: "35 Rue Saint-Honoré, 75001 Paris",
        text: "Good pizzas, try the teglia !",
        pref_food: "Karaage curry",
        menu: "http://www.pizzawawa.com/",
        maps: "https://goo.gl/maps/z8VkPkLWd8tDdje88",
      },
      {
        name: "Santa Carne",
        image: "santacarne.jpg",
        type: "Argentinian",
        address: "3 Bd Richard-Lenoir, 75011 Paris",
        text: "Incredibile argentinian food",
        pref_food: "Empanadas",
        menu: "https://santacarne.com/menu/",
        maps: "https://goo.gl/maps/wfmQ7fURPhJr2wD89",
      },
      {
        name: "Tonton des Dames",
        image: "tontondesdames.jpg",
        type: "Meat",
        address: "94 Rue des Dames, 75017 Paris",
        text: "Meat specialized restaurant",
        pref_food: "L. Bone de boeuf Salers",
        menu: "https://www.tontondesdames.fr/menus-carte/",
        maps: "https://goo.gl/maps/8Ma7vELts9NHmc7g8",
      },
      {
        name: "Arrivederci Pizzeria",
        image: "pizzeria.jpg",
        type: "Pizza",
        address: "47 Rue Gay-Lussac, 75005 Paris",
        text: "Very good special pizzas",
        pref_food: "Principessa",
        menu: "https://arrivedercipizzeria.com/",
        maps: "https://goo.gl/maps/EpSoc3NNfUqR7hLY6",
      },
      {
        name: "Le relais de l'entrecote",
        image: "entrecote.jpg",
        type: "Meat",
        address: "15 Rue Marbeuf, 75008 Paris",
        text: "My favorite meat restaurant",
        pref_food: "Entrecote",
        maps: "https://goo.gl/maps/AyAAudXf5wFRMrtr9",
      },
      {
        name: "Ma Lucia",
        image: "malucia.jpg",
        type: "Italian",
        address: "54 Rue Basfroi, 75011 Paris",
        text: "A very good italian canteen",
        pref_food: "Lasagnes",
        menu: "https://app.dood.com/fr/paris/cuisines-du-monde/ma-lucia",
        maps: "https://goo.gl/maps/k4DWpnvPEz4WWagH8",
      },
      {
        name: "Hakata Choten",
        image: "hakatachoten.jpg",
        type: "Japanese",
        address: "53 Rue des Petits Champs, 75001 Paris",
        text: "One of the best place for gyozas",
        pref_food: "Gyozas",
        menu: "http://www.hakata-choten.com/menu/",
        maps: "https://goo.gl/maps/yJSbY1mqHXgmvb9B8",
      },
      {
        name: "Avec amour",
        image: "avecamour.jpg",
        type: "Burgers",
        address: "78 Rue de Belleville, 75020 Paris",
        text: "Tastiest burgers ever made",
        pref_food: "Amour",
        menu: "https://avec-amour-burger.com/",
        maps: "https://goo.gl/maps/nKQDWm63LwD6YMcPA",
      },
      {
        name: "Tonkatsu Tombo",
        image: "tonkatsutombo.png",
        type: "Japanese",
        address: "14 Rue de l'Arrivée, 75015 Paris",
        text: "Very good japanese fried food",
        pref_food: "Tonkatsu",
        menu: "menutombo.png",
        maps: "https://goo.gl/maps/HzbvCMvTRs6ytehc8",
      },
    ],
  };
},
};
</script>