<template>
	<div id="app" class="grid grid-cols-1 lg:grid-cols-[250px_1fr] min-h-screen">
<ToggleNavbar>
</ToggleNavbar>
			<h1 class="center">The notebook</h1>
			<p>
				Hello dear traveler.<br>
				To the food lover you are, the very true seeker of respectable
				and yummy sustenance : may you come in this heaven satisfied.<br>
				Here is my treasure, after years of testings, findings, culinary
				orgasms and fresh frozen "homemade" food I can finally give you my notebook.<br>
				I tracked every restaurant I ever stepped in that left me with a
				feeling of greatness and dutifully noted it to make the perfect notebook.
				<br><br>Everything is related to my taste of food, if you don't
				like it you're one click away.
				<br><br> You can find what you want by type of food. There is no rating, everything is good and you will have a fantastic time. Enjoy !
			</p>
	</div>
</template>


<style>
a {
	color: white;
	font-weight: bold;
}
* {
	margin: 0;
}


@font-face {
	font-family: "Bonche-Light";
	src: local("Bonche Light"),
	url(../fonts/Bonche-Light.otf) format("truetype");
}
</style>
<script>
import ToggleNavbar from '@/components/ToggleNavbar.vue';
export default {
components: {
  ToggleNavbar
}
}
</script>
