import { createRouter, createWebHistory } from 'vue-router'
import HomeVue from '@/components/HomeVue.vue'
import RestaurantsVue from '@/components/RestaurantsVue.vue'
import AboutMe from '@/components/AboutMe.vue'


const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeVue,
	},
	{
		path: '/restaurants',
		name: 'restaurants',
		component: RestaurantsVue,
	},
	{
		path: '/about',
		name: 'about',
		component: AboutMe
	},
]


const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
