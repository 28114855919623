<template>
	<div id="app" class="grid grid-cols-1 lg:grid-cols-[250px_1fr] min-h-screen">
		<ToggleNavbar></ToggleNavbar>
			<h1 class="center">About me</h1>
			<p class="center-main">
			I am Ben, 25 years old and based in Paris. I am doing <strike>IT</strike> RH for a living. I love food, and I want to share it as much as I can.
			There will be more to come here, but for now I'm too lazy.
			</p>
		</div>
</template>
<style>
</style>
<script>
import ToggleNavbar from './ToggleNavbar.vue';
export default {
components: {
  ToggleNavbar
}
}
 
</script>