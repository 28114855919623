<template>
	<head>
		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		<title>Kodadvisor</title>
	</head>
	<router-view></router-view>
</template>

<style>
	#app {
	background-color: #1e293b;
	font-family: "Bonche-Light";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: white;
	text-align: center;
	}
</style>

<script></script>
