<template>
    <div class="flex flex-col">
      <nav class="bg-gray-800 text-white p-4 sticky top-0 left-0 w-full lg:fixed lg:top-0 lg:left-0 lg:w-1/6 lg:h-full">
        <div class="container mx-auto flex justify-between items-center lg:flex-col lg:items-center lg:mb-4">
          <div class="lg:hidden text-xl font-bold">Kodadvisor</div>
            <img src="../assets/kodacarre.png" alt="Logo" class="hidden md:block w-32 h-auto ml-4 rounded-full"/>
          <button @click="toggleNavbar" class="lg:hidden">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
        <div :class="['lg:flex  lg:flex-col lg:w-full sidebar', isNavbarOpen ? 'block' : 'hidden']">
        
        <router-link class="block py-2 px-4 text-sm hover:bg-gray-700 lg:w-full lg:py-3 lg:px-6" to="/">Home</router-link>
        <router-link class="block py-2 px-4 text-sm hover:bg-gray-700 lg:w-full lg:py-3 lg:px-6" to="/restaurants">Restaurants</router-link>
        <router-link class="block py-2 px-4 text-sm hover:bg-gray-700 lg:w-full lg:py-3 lg:px-6" to="/about">About me</router-link>
        </div>
      </nav>
    </div>
  </template>

  <script>
  export default {
    data() {
      return {
        isNavbarOpen: false,
      };
    },
    methods: {
      toggleNavbar() {
        this.isNavbarOpen = !this.isNavbarOpen;
      },
    },
  };
  </script>
  
  <style scoped>
.sidebar {
	padding: 15px;
	border-top: solid 1px;
}
@media screen and (max-height: 450px) {
	.sidebar {padding-top: 15px;}
	.sidebar a {font-size: 18px;}
  }

.sidebar a {
	text-decoration: none;
	transition: 0.3s;
}

.sidebar a:hover {
	color: #999999;
}
  </style>
  